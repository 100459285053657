import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

toast.configure();
export const toaster = (message) => {
    var displayMessage = message.message;
    var errorMessage = "Error Code: " + message.statusCode +"\nError Message: "+ message.message ;
    if (message.statusCode === 200) {
        toast.success(displayMessage, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className:'toast_success_message',
        });
    }

    if (message.statusCode === 400 || message.statusCode === 403 || message.statusCode === 500) {
        toast.error(errorMessage, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            enableHtml: true,
        });
    }

    if (message.statusCode === 401) {
        toast.warn('Session has been expired', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

    }

    if (message.statusCode === 404 || message.statusCode === 502 || message.statusCode === 504) {
        toast.warn(displayMessage, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

    }

    return (
        
            <ToastContainer />
    );
};
