import {Base64} from 'js-base64';

import * as authorization from './authorization';
import * as toaster from './toaster';

import 'react-toastify/dist/ReactToastify.css';

const singout_url = `${process.env.REACT_APP_signout_redirect_url}`;
const access_api_url = `${process.env.REACT_APP_log_access_api_base}`;

export const requestHeader = () => {
    var idToken = authorization.getIdToken();
    const authorizationToken = idToken;
    const header = {
        'Authorization': authorizationToken,
        'Accept': '*',
        'Content-Type': 'application/json;'
    }
    return header;
}

export const setMessage = (res) => {
    const message = {
        statusCode: res.statusCode,
        status: res.status,
        message: res.message
    }
    return message;
}

export const errorResponse = (error, statusCode) => {
    const message = {
        statusCode: statusCode?statusCode:500,
        status: error.name,
        message: error.message
    }
    return message;
}

export const redirectToCognitoSignin = () => {
    return window.location.href = singout_url;
};

export const logout = () => {
    authorization.removeData('access_token');
    authorization.removeData('id_token');
    authorization.removeData('refresh_token');
    authorization.removeData('expires_in');
    authorization.removeData('token_type');
    return window.location.href = singout_url;
};


export const getDeviceMDNList = async () => {
    const api = `${access_api_url}/oem-device-mdn-list`;
    var responseData, responseStatus;
    try {
        const response = await fetch(api, {
            method: 'GET',
            mode: 'cors',
            headers: requestHeader()
        });
        if (!response) {
            throw new Error(`HTTP error! status: No response`);
        } else if( !response.ok ) {
            responseStatus = response.status;
            if (responseStatus === 401) {
                logout();
            } else {
                responseData = await validateAndFetchJSONResponse(response);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }
        responseData = await validateAndFetchJSONResponse(response);
    } catch (error) {
        console.error('Failed to parse JSON:', error);
        if( !responseData ) {
            responseData = errorResponse(error, responseStatus);
        }
    }
    var message = setMessage(responseData);
    if (responseData.statusCode === 200) {
        return responseData;
    } else if (responseData.statusCode !== 200 && responseData.statusCode !== undefined) {
        toaster.toaster(message);
        return null;
    }
};

export const getAccessLogListFromElasticSearch = async (accessLogData) => {
    const api = `${access_api_url}/oem-testing-log-archive`;
    const formBody = JSON.stringify(accessLogData);
    var responseData, responseStatus;
    try {
        const response = await fetch(api, {
            method: 'POST',
            mode: 'cors',
            headers: requestHeader(),
            body: formBody
        });
        if (!response) {
            throw new Error(`HTTP error! status: No response`);
        } else if( !response.ok ) {
            responseStatus = response.status;
            if (responseStatus === 401) {
                logout();
            } else {
                responseData = await validateAndFetchJSONResponse(response);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }
        responseData = await validateAndFetchJSONResponse(response);
    } catch (error) {
        console.error('Failed to parse JSON:', error);
        if( !responseData ) {
            responseData = errorResponse(error, responseStatus);
        }
    }
    
    var message = setMessage(responseData);
    
    if (responseData.statusCode === 200) {
        toaster.toaster(message);
        return responseData;
    } else if (responseData.statusCode !== 200 && responseData.statusCode !== undefined) {
        toaster.toaster(message);
        return null;
    }
};

export const getAccessLogListFromS3 = async () => {
    const api = `${access_api_url}/oem-testing-log-archive-list`;
    var responseData, responseStatus;
    try {
        const response = await fetch(api, {
            method: 'GET',
            mode: 'cors',
            headers: requestHeader()
        });
        if (!response) {
            throw new Error(`HTTP error! status: No response`);
        } else if( !response.ok ) {
            responseStatus = response.status;
            if (responseStatus === 401) {
                logout();
            } else {
                responseData = await validateAndFetchJSONResponse(response);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }
        responseData = await validateAndFetchJSONResponse(response);
    } catch (error) {
        console.error('Failed to parse JSON:', error);
        if( !responseData ) {
            responseData = errorResponse(error, responseStatus);
        }
    }
    var message = setMessage(responseData);
    if (responseData.statusCode === 200) {
        toaster.toaster(message);
        const updatedResponseData = updateAccessTime(responseData);
        return updatedResponseData;
    } else if (responseData.statusCode !== 200 && responseData.statusCode !== undefined) {
        toaster.toaster(message);
        return null;
    }
};

export const downloadAccessLogById = async (s3AccessLogId) => {
    const api = `${access_api_url}/download/oem-testing-log-archive/${s3AccessLogId}`;

    var responseData, responseStatus;
    try {
        const response = await fetch(api, {
            method: 'GET',
            mode: 'cors',
            headers: requestHeader()
        });
        if (!response) {
            throw new Error(`HTTP error! status: No response`);
        } else if( !response.ok ) {
            responseStatus = response.status;
            if (responseStatus === 401) {
                logout();
            } else {
                responseData = await validateAndFetchJSONResponse(response);
                throw new Error(`HTTP error! status: ${responseStatus}`);
            }
        } 
        try {
            const responseBlob = await response.blob();
            if(responseBlob) {
                const blobText = await responseBlob.text();
                responseData = JSON.parse(blobText);
                if( !responseData ){
                    throw new Error('Bad Response');
                }
             } else {
                throw new Error('Bad Response');
            }
        } catch (error) {
            throw new Error('Failed to parse to json');
        }
    } catch (error) {
        console.error('Failed to parse JSON:', error);
        if( !responseData ) {
            responseData = errorResponse(error, responseStatus);
        }
    }
    const message = setMessage(responseData);
    if (responseData.statusCode !== 200 && responseData.statusCode !== undefined) {
        toaster.toaster(message);
        return null;
    }
    var BOM = '\uFEFF';
    var fileData = BOM + Base64.decode(responseData.data);
    const blob = new Blob([fileData], { type: 'text/csv;charset=utf-8' });

    const blobURL = window.URL.createObjectURL(blob);

    // Create new tag for download file
    const anchor = document.createElement('a');
    anchor.download = s3AccessLogId.slice(0, -4);
    anchor.href = blobURL;
    anchor.dataset.downloadurl = ['text/csv;charset=utf-8', anchor.download, anchor.href].join(':');
    anchor.click();

    // Remove URL.createObjectURL. The browser should not save the reference to the file.
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
    }, 100);
};

export const updateAccessTime = async (logList) => {
    logList.data.forEach((log) => {
        const localDate = new Date(log.accessTime);
        let customDate = localDate.toLocaleDateString('en-us', { month: 'short', day: 'numeric', year: 'numeric' });
        let customTime = localDate.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
        const finalAccessTime = customTime + ", " + customDate;
        log.accessTime = finalAccessTime;
    });
    return logList;
};

const validateAndFetchJSONResponse = async(response) => {
    if (!response.headers ) {
        throw new Error(`Headers are missing`);
    } 
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
            const data = await response.text();
            if(data) {
                const jsonResponse = JSON.parse(data);
                if( !jsonResponse ){
                    throw new Error('Bad Response');
                }
                return jsonResponse;
            } else {
                throw new Error('Bad Response');
            }
    } else {
        throw new Error("Expected JSON but: "+ contentType);
    }
};
