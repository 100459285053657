import jwt from 'jwt-decode';
import Cookies from 'js-cookie';

export const getDecodedAccessToken = (token) => {
    try {
        return jwt(token);
    } catch (Error) {
        return null;
    }
};

// Storing data in cookies
export const saveData = (key, value) => {
    Cookies.set(key, JSON.stringify(value), { secure: true, sameSite: 'Strict' });
};

// Retrieving data from cookies
const getData = (key) => {
    const data = Cookies.get(key);
    return data ? JSON.parse(data) : null;
};

export const removeData = (key) => {
    Cookies.remove(key);
};

export const isAuthenticated = () => {
    if (getData('access_token') != null) {
        return true;
    } else {
        return false;
    }
};

export const getAccessToken = () => {
    return getData('access_token');
};

export const getIdToken = () => {
    return getData('id_token');
};

export const getExpiresIn = () => {
    return getData('expires_in');
};



