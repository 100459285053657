import React, { useEffect, useState, useRef } from 'react';
import { Table, Button, Form, Container } from 'react-bootstrap';

import { Col, Row } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faRotate } from '@fortawesome/free-solid-svg-icons';
import { Oval} from 'react-loader-spinner'

import * as dashboardService from '../services/dashboardService';
import * as authorization from '../services/authorization';
import * as toaster from '../services/toaster';
import moment from "moment";
import '../styling/dashboard.scss';

import { Beforeunload } from 'react-beforeunload';

function Dashboard() {

    const [loading, setLoading] = useState(true);
    const [deviceMDNLoading, setDeviceMDNLoading] = useState(false);
    const currentUserRef = useRef('');
    const currentUserNameRef = useRef('');
    const [deviceMdnList, setDeviceMdnList] = useState([]);
    const [deviceMdn, setDeviceMdn] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [accessLog, setAccessLog] = useState([]);
    const today = new Date();
    const year = new Date().getFullYear();
    const minimumStartDate = new Date(new Date().setDate(new Date().getDate() - 29));
    const [maxEndDate, setMaxEndDate] = useState('');
    let currentUTCDate = new Date(new Date().setDate(new Date().getUTCDate()));
    let timer;

    const sessionMessage = {
        statusCode: 401
    }

    const handleLogout = () => {
        dashboardService.logout();
    }

    const handleDownload = (s3AccessLogId) => {
        if (s3AccessLogId) {
            dashboardService.downloadAccessLogById(s3AccessLogId);
        }
    }

    const getDeviceMDNList = () => {
        dashboardService.getDeviceMDNList().then((deviceMDN) => {
            if (deviceMDN) {
                setDeviceMdnList(deviceMDN.data);
                setDeviceMDNLoading(false);
            } else {
                setDeviceMDNLoading(false);
            }
        });
    }

    const handleGenerateReport = (event) => {
        event.preventDefault();
        let newEndDate;
        // check if utc > selected_end_Date and selecte_end_date == today's date, then update end_date same as utc_date
        if((currentUTCDate >= new Date(endDate)) && (moment(endDate).toDate().toDateString() === moment(new Date()).toDate().toDateString())  ){
            newEndDate = moment(new Date(currentUTCDate)).format('YYYY-MM-DD');
        } else {
            newEndDate = moment(endDate).format('YYYY-MM-DD');            
        }
        const formData = {
            endDate: newEndDate,
            startDate: startDate,
            deviceMdn: deviceMdn
        }
        setLoading(true);
        dashboardService.getAccessLogListFromElasticSearch(formData).then((accessLogData) => {
            if (accessLogData) {
                setAccessLog([]);
                handleGetAccessLogListFromS3();
            } else {
                setLoading(false);
            }
        });

    }

    const handleGetAccessLogListFromS3 = () => {
        dashboardService.getAccessLogListFromS3().then((updatedAccessLogData) => {
            if (updatedAccessLogData) {
                setAccessLog(updatedAccessLogData.data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }

    const handleLogoutTimer = () => {
        let sesionExpireTime = parseInt(authorization.getExpiresIn())*1000;
        timer = setTimeout(() => {
            resetTimer();
            toaster.toaster(sessionMessage);
            dashboardService.logout();
        }, sesionExpireTime); // time in seconds.
    }

    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

    const removeApplicationData = () => {
        if (window) { // window-check.
            // clear the local-storage on closing the tab/window
            dashboardService.logout();
        }
    };

    const handleTokenParsing = () => {
        let cognitoAuthToken = '';
        cognitoAuthToken = window.location.hash;
        const cognitoAuthTokenValue = cognitoAuthToken.substring(1);

        if (cognitoAuthTokenValue) {
            const cognitoToken = cognitoAuthTokenValue.split('&', 3);
            cognitoToken.forEach((token) => {
                const keyValue = token.split('=', 2);
                authorization.saveData(keyValue[0], keyValue[1]);
            });
            // Decode and store user info from the token
            if (!authorization.getIdToken()) {
                handleLogout();
            }
            // Redirect after saving token data
            window.location.href = `${process.env.REACT_APP_signin_redirect_url}`;
            window.location.hash = ''; // Clear hash from URL
            setLoading(false);
        } else {
            handleLogout();
        }
    };

    useEffect(() => {
        if ( window.location.hash ) {
            handleTokenParsing();
        } else if (!authorization.isAuthenticated()) {
            dashboardService.redirectToCognitoSignin();
        }
        
        if(authorization.isAuthenticated()) {
            handleLogoutTimer();
            setDeviceMDNLoading(true);
            getDeviceMDNList();
            handleGetAccessLogListFromS3();
            if(currentUserRef.current === '') {
                const decodedToken = authorization.getDecodedAccessToken(authorization.getIdToken());
                if (!decodedToken) {
                    handleLogout();
                }
                currentUserRef.current = decodedToken ? decodedToken['email'] : '';
                currentUserNameRef.current = decodedToken ? decodedToken['name'] : '';
            }
        }
    }, []);
    
    if (!authorization.isAuthenticated()) {
        return (
            <div>
              <h3>Loading login page...</h3>
            </div>
          );    
    } else {
        
        return (

            <Container>
                <Beforeunload onBeforeunload={removeApplicationData}>
                </Beforeunload>
                {/* Profile */}
                <Row>
                    <Col>
                        <div className='header'>
                            <img src='NextNavLogo.png' alt='NextNav Logo' className='logo' />
                        </div>
                    </Col>
                    <Col>
                        <div className='header'>
                            <h4 className='title'>Device Test Reports</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className='header profile'>
                            <p className='userName'>
                                {(currentUserNameRef.current && currentUserNameRef.current !== '') ?
                                    currentUserNameRef.current + '( ' + currentUserRef.current + ' )' : currentUserRef.current} </p>
                            <button variant='text' type='button' onClick={handleLogout} className='btn btn-link logoutButton'>
                                Logout
                            </button>
                        </div>
                    </Col>


                </Row>

                {/* Log Access Form */}
                <Form onSubmit={handleGenerateReport} className='logAccessForm' >
                    <Row>

                        <Col>
                            <Form.Group className='mb-3' controlId='formMdn'>
                                <Form.Label >Device MDN</Form.Label>
                                <Form.Select onChange={event => { setDeviceMdn(event.target.value);}}>
                                    <option value="default"> -- Select Device MDN -- </option>
                                    {deviceMdnList.length > 0 ?
                                        deviceMdnList.map((deviceMsdn) => <option key={deviceMsdn} value={deviceMsdn}>{deviceMsdn}</option>) : <option value="none" disabled> No Data </option>}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='formStartDate'>
                                <Form.Label >Start Date</Form.Label>
                                <Form.Control type='date' placeholder='Enter start date' value={startDate} onChange={
                                    event => {
                                        setStartDate(event.target.value);
                                        var selectedStartDate = moment(moment(event.target.value)).toDate();
                                        var maximumEndDate = new Date(new Date(selectedStartDate).setDate(selectedStartDate.getDate() + 2));
                                        setMaxEndDate(moment(maximumEndDate).format('YYYY-MM-DD'));
                                        setEndDate('');
                                    }}
                                    max={moment(today).format('YYYY-MM-DD')}
                                    min={moment(minimumStartDate).format('YYYY-MM-DD')}
                                    required />
                                <Form.Text className='text-muted'></Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='formEndDate'>
                                <Form.Label >End Date</Form.Label>
                                <Form.Control type='date' placeholder='Enter end date' value={endDate} onChange={event => {
                                    setEndDate(event.target.value);
                                }} 
                                min={startDate === '' ? moment(minimumStartDate).format('YYYY-MM-DD') : startDate}
                                max={startDate === '' ? moment(today).format('YYYY-MM-DD') : moment(maxEndDate).format('YYYY-MM-DD') >= moment(today).format('YYYY-MM-DD') ? moment(today).format('YYYY-MM-DD') : moment(maxEndDate).format('YYYY-MM-DD')}
                                required />
                                <Form.Text className='text-muted'></Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* Button */}
                    <Row>
                        <Col>
                            <button variant='custom' type='submit' size='lg' className='btn btn-primary' disabled={
                                ((startDate > endDate) || (startDate === '' || endDate === '' || deviceMdn === '' || deviceMdn === 'default' || deviceMdn === 'none')) ? true : false}>
                                {loading && <span><FontAwesomeIcon icon={faRotate} style={{ marginRight: '5px', paddingRight: '5px' }}  />Loading Data from Server</span>}
                                {!loading && <span>Generate Report</span>}
                            </button>
                        </Col>
                    </Row>
                </Form>

                {/* Log Access List */}
                <Row>
                    <Col>
                        <h6 className='reportTitle'>Recent Searches</h6>
                    </Col>
                </Row>

                {/* Report List */}
                <Row>
                    <Col>
                        <Table responsive striped bordered hover className='reportList'>
                            <thead>
                                <tr>
                                    <th >Reports</th>
                                    <th >Size (bytes) </th>
                                    <th >Report Generation Time</th>
                                    <th >Download</th>
                                </tr>
                            </thead>
                            <tbody >
                                {accessLog.length > 0 ?
                                    accessLog.map((log, index) => (
                                        <tr data-index={index} key={index}>
                                            <td className='fileName' onClick={() => { handleDownload(log.fileName) }}><u>{log.fileName}</u></td>
                                            <td >{log.size}</td>
                                            <td >{log.accessTime}</td>
                                            <td >
                                                <Button variant='link' onClick={() => { handleDownload(log.fileName) }} className='downloadButton'>
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr >
                                        <td colSpan='4' className='noData'>
                                            No records Found.
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p className='footer'>©{year} NextNav. All rights reserved.</p>
                    </Col>
                </Row>
                {loading &&
                    <Oval
                        height="80"
                        width="80"
                        radius="9"
                        color="#3498db"
                        ariaLabel="Loading Data"
                        secondaryColor="#0d6efd"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                        wrapperStyle={{
                            display: 'flex',
                            position: 'fixed ',
                            inset: '0px',
                            background: 'rgba(0, 0, 0, 0.7)',
                            width: '100%',
                            justifyContent: 'center',
                            paddingTop: '22.5%',
                        }}
                        wrapperClass
                        visible={loading || deviceMDNLoading}
                    />
                }
                {/*  */}
            </Container>

        )
    }
}


export default Dashboard;
